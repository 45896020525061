<script>
import {ref} from "vue";

export default {
    props: {
        plans: {
            type: Array,
            default: []
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        withPadding: {
            type: Boolean,
            default: true
        },
        subscribed: {
            type: Boolean,
            default: false
        },
        hasExpiredTrial: {
            type: Boolean,
            default: false
        },
        onTrial: {
            type: Boolean,
            default: false
        },
        trialEndsAt: {
            type: String,
            default: ''
        },
    },
    methods: {
        planHref(p, f) {
            if (this.subscribed) {
                return this.$inertia.visit(route('billing.portal'));
            }
            return this.$inertia.visit(route('billing.init', { package: p, frequency: f }));
        },
    }
}
</script>

<template>
    <div :class="withPadding ? 'sm:pt-52 pt-32' : ''">
        <div class="mx-auto" :class="withPadding ? 'max-w-7xl px-6 lg:px-8' : ''">
            <div v-if="showTitle" class="mb-16">
                <div class="mx-auto max-w-4xl text-center">
                    <h2 class="text-base font-semibold leading-7 text-teal-400">Pricing</h2>
                    <p class="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">Pricing plans for every need</p>
                </div>
                <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">Choose an affordable plan that’s packed with the features you need, at a price that fits your budget.</p>
            </div>

            <div class="flex" :class="showTitle ? 'justify-center' : 'justify-left'">
                <RadioGroup v-model="frequency" class="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white">
                    <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>
                    <RadioGroupOption as="template" v-for="option in frequencies" :key="option.value" :value="option" v-slot="{ checked }">
                        <div :class="[checked ? 'bg-emerald-600 text-white' : '', 'cursor-pointer rounded-full py-1 px-2.5']">
                            <span v-html="option.label"></span>
                        </div>
                    </RadioGroupOption>
                </RadioGroup>
            </div>

            <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 xl:mx-0 xl:max-w-none xl:grid-cols-3">
                <div v-for="plan in plans" :key="plan.name" :class="[plan.most_popular ? 'shadow-none bg-zinc-900/60 backdrop-blur-xl' : 'ring-1 ring-white/10', 'rounded-3xl p-8 xl:p-10']">
                    <div class="flex items-center justify-between gap-x-4">
                        <h3 :id="plan.name" class="text-lg font-semibold leading-8 text-white">{{ plan.name }}</h3>
                        <p v-if="plan.most_popular" class="rounded-full bg-emerald-800/20 text-emerald-500 py-1 px-2.5 text-xs font-semibold leading-5">Recommended</p>
                    </div>
                    <p class="mt-4 text-sm leading-6 text-gray-300">{{ plan.description }}</p>
                    <p class="mt-6 flex items-baseline gap-x-1">
                        <span class="text-4xl font-bold tracking-tight text-white">{{ plan.currency_symbol + plan.price[frequency.value] }}</span>
                        <span class="text-sm font-semibold leading-6 text-gray-300">{{ frequency.priceSuffix }}</span>
                    </p>
                    <p v-if="frequency.value === 'yearly'" class="text-xs leading-6 text-gray-400">Billed yearly</p>

                    <PrimaryButton force-dark-mode v-if="plan.most_popular" @click="planHref(plan.name, frequency.value)" :aria-describedby="plan.name" class="mt-6">
                        {{ hasExpiredTrial ? 'Subscribe' : 'Start free 7-day trial' }}
                    </PrimaryButton>
                    <SecondaryButton force-dark-mode v-else @click="planHref(plan.name, frequency.value)" :aria-describedby="plan.name" class="mt-6">
                        {{ hasExpiredTrial ? 'Subscribe' : 'Start free 7-day trial' }}
                    </SecondaryButton>
                    <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                        <li v-for="feature in plan.features" :key="feature" class="flex gap-x-3">
                            <CheckIcon class="h-6 w-5 flex-none text-white" aria-hidden="true" />
                            <span v-html="feature"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
import {Link, router} from '@inertiajs/vue3';
import PrimaryButton from "../../Components/PrimaryButton.vue";
import SecondaryButton from "../../Components/SecondaryButton.vue";

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'yearly', label: 'Yearly <span class="opacity-50 text-xs">(save 20%)</span>', priceSuffix: '/month' },
]

let frequency = ref(frequencies[0])
</script>
